var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-page" }, [
    _vm._m(0),
    _c("div", { staticClass: "code" }, [
      _c("h2", [_vm._v("Veuillez saisir votre code d’invitation")]),
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit()
            }
          }
        },
        [
          _c("div", { staticClass: "code-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.readKey,
                  expression: "readKey"
                }
              ],
              attrs: {
                type: "text",
                autocomplete: "off",
                autocorrect: "off",
                autocapitalize: "off",
                spellcheck: "false"
              },
              domProps: { value: _vm.readKey },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.readKey = $event.target.value
                }
              }
            }),
            _c(
              "button",
              {
                attrs: {
                  type: "submit",
                  title: "Valider",
                  disabled: _vm.isSubmitDisabled
                }
              },
              [_c("img", { attrs: { src: "/img/submit.svg" } })]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "intro" }, [
      _c("img", { attrs: { src: "/img/logo-home.svg" } }),
      _c("h1", [
        _vm._v("Bienvenue sur l’application"),
        _c("br"),
        _vm._v("de diffusion en direct de Movin’Smart")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }