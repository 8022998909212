<template lang="pug">
  .home-page
    .intro
      img(src="/img/logo-home.svg")
      h1 Bienvenue sur l’application<br>de diffusion en direct de Movin’Smart
    .code
      h2 Veuillez saisir votre code d’invitation
      form(@submit.prevent="onSubmit()")
        .code-input
          input(type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="readKey")
          button(type="submit" title="Valider" :disabled="isSubmitDisabled")
            img(src="/img/submit.svg")
</template>

<script>
export default {
  data () {
    return {
      readKey: ''
    }
  },
  computed: {
    isSubmitDisabled () {
      return !this.readKey
    }
  },
  methods: {
    onSubmit () {
      if (this.isSubmitDisabled) return
      window.location = `/stream/${this.readKey}`
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/variables-mixins';

  .home-page {
    text-align: center;
  }

  .intro {
    margin-top: 4rem;
    margin-bottom: 4rem;

    img {
      margin-bottom: 0.5rem;
    }
  }

  .code-input {
    display: inline-block;
    border: 0.1rem solid rgba($color: black, $alpha: 0.1);
    background: white;
    border-radius: 0.6rem;

    &:focus-within {
      outline: auto;
    }

    input[type=text] {
      width: 12rem;
      font-size: 1.625rem;
      font-weight: bold;
      color: $color-text-light;
      border: none;
      background: none;
      padding: 0.35rem 0 0.2rem 0.6rem;
      vertical-align: middle;
      outline: none;
    }

    button[type=submit] {
      border: none;
      background: none;
      padding: 0;
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      vertical-align: middle;

      img {
        width: 1.8rem;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
</style>
